import fetch from '../utils/fetch'

// 注册弹窗列表
export function getRegisterList (query) {
  return fetch({
    url: '/scene/settings',
    method: 'get',
    params: query
  })
}

// 注册弹窗添加
export function addRegister(query) {
  return fetch({
    url: '/scene/settings/add',
    method: 'post',
    params: query
  })
}

// 注册弹窗编辑
export function editRegister(id, query) {
  return fetch({
    url: '/scene/settings/' + id,
    method: 'put',
    params: query
  })
}

// 注册弹窗删除
export function deleteRegister(id, query) {
  return fetch({
    url: '/scene/settings/' + id,
    method: 'delete',
    params: query
  })
}