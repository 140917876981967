var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"view-flex"},[(_vm.checked.media_id)?_c('div',{staticClass:"video-box"},[_c('video-player',{staticClass:"vjs-custom-skin",attrs:{"options":{
          preload: 'auto',
          aspectRatio: '16:9',
          fluid: true,
          sources: [
            {
              // mp4
              type: 'video/mp4',
              src: _vm.checked.url
            }
          ],
          notSupportedMessage: '此视频暂无法播放，请稍后再试',
          controlBar: false
        }}})],1):_vm._e(),(!_vm.checked.media_id || (_vm.checked.media_id && _vm.multiple))?_c('div',{staticClass:"upload-box",on:{"click":_vm.showVideos}},[_c('i',{staticClass:"iconfont icon-video"})]):_vm._e()]),_c('videoDialog',{attrs:{"checked":_vm.checked},on:{"changeChecked":_vm.changeChecked},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }