<template>
  <div>
    <div class="view-flex">
      <div
        v-if="checked.media_id"
        class="video-box"
      >
        <video-player
          class="vjs-custom-skin"
          :options="{
            preload: 'auto',
            aspectRatio: '16:9',
            fluid: true,
            sources: [
              {
                // mp4
                type: 'video/mp4',
                src: checked.url
              }
            ],
            notSupportedMessage: '此视频暂无法播放，请稍后再试',
            controlBar: false
          }"
        />
      </div>
      <div
        v-if="!checked.media_id || (checked.media_id && multiple)"
        class="upload-box"
        @click="showVideos"
      >
        <i class="iconfont icon-video" />
      </div>
    </div>
    <videoDialog v-model="visible" :checked="checked" @changeChecked="changeChecked" />
  </div>
</template>
<script>
import videoDialog from './videoDialog'
export default {
  props: {
    multiple: {
      type: Boolean,
      default: true
    },
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {
    videoDialog
  },
  data () {
    return {
      checked: '',
      visible: false
    }
  },
  watch: {
    data (val) {
      this.checked = val
    }
  },
  created () {
    this.checked = this.data
  },
  methods: {
    showVideos () {
      this.visible = true
    },
    changeChecked(temp) {
      this.checked = temp
      this.$emit('change', this.checked)
    }
  }
}
</script>

<style scoped lang="scss">
.upload-box {
  border: 1px dashed #c0ccda;
  border-radius: 6px;
  cursor: pointer;
  overflow: hidden;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;

  .iconfont {
    font-size: 30px;
    color: #ccc;
  }
}

.video-box {
  margin-right: 10px;
  width: 213px;
  height: 120px;
  border: 1px dashed #c0ccda;

  video {
    width: 100%;
    height: 100%;
  }
}
</style>
